<div>
  <div class="sub-header">
    <div class="main-container container">
      <div class="col-12 col-md-6 col-lg-7 align-item-center d-flex">
        <div class="mt-3">
          <h1 class="page-title m-0">Patient</h1>
          <ol class="breadcrumb flex-nowrap">
            <li class="breadcrumb-item">
              <a routerLink="/">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a routerLink="/account">Account</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page" id="networkList">
              Patient
            </li>
          </ol>
        </div>
      </div>

      <div class="card mt-1 mb-5 p-2">
        <div class="table-responsive">
          <table id="data-table" class="table text-nowrap m-0 table-bordered table-striped">
            <thead class="table-head" *ngIf="list.length >0 && !showLoading">
              <tr class="text-secondary bg-gray-200">
                <th>
                  MEMBER ID
                </th>
                <th>
                  PATIENT NAME
                </th>
                <th class="text-center">
                  NEPHROLOGIST
                </th>
                <th class="text-center">
                  DOB (AGE)
                </th>
                <th class="text-center">
                  Attributed
                </th>
                <th class="text-center">
                  Engaged
                </th>
                <th class="text-center">
                  Assessed
                </th>
                <th class="text-center">
                  PMPM Amount
                </th>
                <th class="text-center">
                  STATUS
                </th>
              </tr>
            </thead>
            <tbody *ngIf="showLoading" class="table-body">
              <tr *ngFor="let i of getIterations()">
                <td><span class="skeleton-loader my-1"></span></td>
                <td><span class="skeleton-loader my-1"></span></td>
                <td><span class="skeleton-loader my-1"></span></td>
                <td><span class="skeleton-loader my-1"></span></td>
                <td><span class="skeleton-loader my-1"></span></td>
                <td><span class="skeleton-loader my-1"></span></td>
                <td><span class="skeleton-loader my-1"></span></td>
                <td><span class="skeleton-loader my-1"></span></td>
                <td><span class="skeleton-loader my-1"></span></td>
                <td><span class="skeleton-loader my-1"></span></td>
              </tr>
            </tbody>
            <tbody *ngIf="!showLoading" class="table-body">
              <tr *ngFor="let item of list">
                <td>
                  <div class="d-flex align-items-center">
                    <div class="user-details ms-2">
                      <h6 class="mb-0">{{ item.patient?.enrollmentNo }}</h6>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <i data-bs-toggle="tooltip" data-bs-placement="top"
                      [title]="item.isRegistered ? 'Registered on MySomatus' : 'Not Registered on MySomatus'" class="fa"
                      [ngClass]="!item.isRegistered ? 'fa-times-circle text-danger' : 'fa-check-circle text-success'"></i>
                    <div class="user-details ms-2">
                      <h6 class="mb-0">{{ item.patient?.name }}</h6>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <div class="user-details ms-2">
                      {{ item.nephrologistName ? item.nephrologistName : '-' }}
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  {{ getFormatDate(item.dob)
                  }}<strong class="fw-semibold"> ({{ getAge(item.dob) }})</strong>
                </td>
                <td class="text-center">
                  {{ item.attributed? 1 : 0 }}
                  <strong class="fw-semibold"></strong>
                </td>
                <td class="text-center">
                  {{ item.engaged? 1 : 0 }}
                  <strong class="fw-semibold"></strong>
                </td>
                <td class="text-center">
                  {{ item.assessed? 1 : 0 }}
                  <strong class="fw-semibold"></strong>
                </td>
                <td class="text-center"> $
                  {{ item.pmpmAmount  }}
                </td>
                <td class="text-center">{{ item.status }}</td>
              </tr>
            </tbody>
          </table>
          <ng-container *ngIf="!showLoading && list.length > 0">
            <div class="me-5 mt-4 mb-2 d-inline-block">
              <i class="fa fa-check-circle text-success fs-14"></i> Registered on MySomatus
            </div>
            <div class="me-5 mt-4 mb-2 d-inline-block">
              <i class="fa fa-times-circle text-danger fs-14"></i> Not Registered on MySomatus
            </div>
          </ng-container>
          <app-no-records-found *ngIf="list.length == 0 && !showLoading"></app-no-records-found>
        </div>
      </div>
    </div>
  </div>
</div>