// payment-report.service.ts

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Observable } from 'rxjs';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class PaymentReportService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    private makeRequest(method: string, url: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>(method, url, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }

    /**
     * 
     * 
     * @param monthYear 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentReportGetMontlyPatientMonthYearGet(monthYear: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPaymentReportGetMontlyPatientMonthYearGet(monthYear: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPaymentReportGetMontlyPatientMonthYearGet(monthYear: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPaymentReportGetMontlyPatientMonthYearGet(monthYear: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (monthYear === null || monthYear === undefined) {
            throw new Error('Required parameter monthYear was null or undefined when calling apiPaymentReportGetMontlyPatientMonthYearGet.');
        }

        return this.makeRequest('get', `${this.basePath}/api/PaymentReport/GetMontlyPatient/${encodeURIComponent(String(monthYear))}`, observe, reportProgress);
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentReportGetPaymentReportGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPaymentReportGetPaymentReportGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPaymentReportGetPaymentReportGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPaymentReportGetPaymentReportGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        return this.makeRequest('get', `${this.basePath}/api/PaymentReport/GetPaymentReport`, observe, reportProgress);
    }
}