import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import * as moment from "moment";
import { PaymentReportService } from "src/app/api-client";
import { ICustomLogEvents, ISessionDetails} from "src/app/interfaces/customLogEvents";
import { GridModel } from "src/app/interfaces/grid.model";
import { LoggingService } from "src/app/services/logging.service";
import { environment } from "src/environments/environment";
import { SharedModule } from "../../../components/shared.module";
import { NgClass, NgIf, NgFor } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-accout-patient-list",
  standalone: true,
  imports: [SharedModule, NgClass, NgIf, NgFor],
  templateUrl: "./account-patient-list.component.html",
  styleUrl: "./account-patient-list.component.scss",
})
export class AccountPatientListComponent implements OnInit {
  monthYear: string="";
  @Output() actionHandler: EventEmitter<any> = new EventEmitter();
  iterationCount: number = 10;
  selectedPractice: any;
  userInfo: any;
  loadingPymentReport: boolean = false;
  gridData: GridModel = {
    items: [],
    pagingModel: {
      pageSize: environment.pageSize,
      totalRecords: 0,
      currentPage: 1,
      totalPages: 0,
    },
  };
  list: any = [];
  showLoading: boolean = false;

  customLog!: ICustomLogEvents;
  sessionDetails!: ISessionDetails;
  customEvent: string = "Accoun Pateint - Web";

  constructor(
    private paymentReportService: PaymentReportService,
    private loggingService: LoggingService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    const monthParam = this.route.snapshot.params['month'];
    const [month, year] = monthParam.split('-');
    this.monthYear = `${month.charAt(0).toUpperCase()}${month.slice(1).toLowerCase()} ${year}`;
  }
  ngOnInit(): void {
    this.loggingService.eventService
      .selectedPracticeSubscription()
      .subscribe((practice) => {
        this.selectedPractice = practice;
      });
    this.loggingService.storeService
      .userInfoSubscription()
      .subscribe((info) => {
        this.userInfo = info;
      });
    this.sessionDetails = this.loggingService.getSessionDetails(
      this.userInfo,
      this.selectedPractice
    );
    this.loggingService.logEventForComponentRendering(
      this.customEvent,
      this.sessionDetails
    );
    this.loadPatient(this.monthYear);
  }
  loadPatient(monthYear: string) {
    this.showLoading = true;
    this.customLog = this.loggingService.createCustomLog("Paient in current Month","","INFO",this.sessionDetails,"/api/PaymentReport/GetmontlyPatient","GET",{});
    this.paymentReportService.apiPaymentReportGetMontlyPatientMonthYearGet(monthYear).subscribe({
        next: (response: any) => {
          if (response) {
            this.list = response.result;
            this.showLoading = false;
            this.customLog.message = "API Call Successful";
            this.loggingService.trackEvent(this.customEvent, this.customLog);
          }
        },
        error: (error) => {
          this.showLoading = false;
        },
      });
  }
  getFormatDate(dob: Date) {
    const date = moment(dob);
    return date.isValid() ? date.format("MM/DD/YYYY") : "";
  }
  getAge(dob: any) {
    return moment().diff(dob, "years");
  }
  getIterations(): number[] {
    return new Array(this.iterationCount);
  }
}
